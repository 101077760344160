import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Scoop from './components/Scoop';
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-N4CKH5DH17';
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview('landing-page');

const App: React.FC = () => {
  const { root, container } = useStyles();

  return (
    <div className={root}>
      <Header />
      <Content
        titleText={{ blueWord: 'Swipe', firstLine: 'with', secondLine: 'friends.' }}
        imageURL="/Home.png"
        subTitleText="Finding new restaurants one swipe at a time."
        storeButtons
      />
      <div className={container}>
        <Content
          titleText={{ blueWord: 'Filter', firstLine: 'to fit', secondLine: 'your diet.' }}
          imageURL="/Settings.png"
          subTitleText="Find the restaurant that meets your requirements."
          reverse
        />
      </div>
      <Content
        titleText={{ blueWord: 'Save', firstLine: 'your', secondLine: 'favorites.' }}
        imageURL="/History.png"
        subTitleText="Keep track of your favorite restaurants with history."
      />
      <div className={container}>
        <Scoop />
      </div>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#DDE7ED',
    margin: -8,
    paddingTop: 20,
  },
  container: {
    display: 'flex',
    backgroundColor: '#E9E9E9',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

export default App;
