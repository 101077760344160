import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core';

interface StyleProps {
  flexDirection: 'row' | 'column';
  width: string;
}

const infoTags = [
  { icon: '/Shield.png', text: "We don't require you to enter any personal information." },
  {
    icon: '/Passport.png',
    text: 'We generate a unique id for each user to anonymously store your gameplay.',
  },
  {
    icon: '/Globe.png',
    text: 'We require access to your device location to search for restaurants in your area.',
  },
  { icon: '/Upload.png', text: 'We store user profile and history information securely on AWS.' },
];

const Scoop: React.FC = () => {
  const isDesktop = useMediaQuery('(min-width:850px)');
  const { root, image, container, text, infoContainer } = useStyles({
    flexDirection: isDesktop ? 'row' : 'column',
    width: isDesktop ? '70%' : '100%',
  });

  return (
    <div className={root}>
      <div className={text}>
        <Typography variant="h3" style={{ fontWeight: 'bold', marginRight: 15 }}>
          Here's the
        </Typography>
        <Typography variant="h3" style={{ fontWeight: 'bold', color: '#3A77D3' }}>
          scoop.
        </Typography>
      </div>
      <div className={container}>
        {infoTags.map(({ icon, text }, index) => (
          <div className={infoContainer} key={index}>
            <img src={icon} alt="icon" className={image} />
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {text}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    display: 'flex',
    marginTop: 30,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
  },
  image: {
    height: 50,
    width: 50,
    margin: 20,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 50,
  },
  text: {
    display: 'flex',
    flexDirection: ({ flexDirection }) => flexDirection,
    marginTop: 30,
    marginBottom: 30,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: ({ width }) => width,
  },
}));

export default Scoop;
