import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const Footer: React.FC = () => {
  const { root } = useStyles();

  return (
    <div className={root}>
      <Typography
        style={{ fontSize: 14 }}
      >{`© JamSquad 2019-${new Date().getFullYear()}. All rights reserved.`}</Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '70%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
  },
}));

export default Footer;
