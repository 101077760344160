import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button';

const Header: React.FC = () => {
  const { root } = useStyles();

  return (
    <div className={root}>
      <img src="/Yummy.png" alt="Yummy Logo" height={32} width={136} />
      <Button href="mailto:info@jamsquad.ca">Contact Us</Button>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '70%',
  },
}));

export default Header;
