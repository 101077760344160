import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import type { ButtonProps } from '@material-ui/core';

const Button: React.FC<ButtonProps> = (props) => (
  <MuiButton
    {...props}
    style={{
      backgroundColor: '#3A77D3',
      color: 'white',
    }}
  />
);

export default Button;
