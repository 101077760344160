import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Theme } from '@material-ui/core';
import ReactGA from 'react-ga';

interface StyleProps {
  flexDirection: 'row' | 'row-reverse' | 'column-reverse';
  imageHeight: number;
  imageWidth: number;
}

interface ContentProps {
  titleText?: {
    blueWord: string;
    firstLine: string;
    secondLine: string;
  };
  subTitleText: string;
  imageURL: string;
  reverse?: boolean;
  storeButtons?: boolean;
}

const Content: React.FC<ContentProps> = ({
  titleText,
  subTitleText,
  imageURL,
  reverse,
  storeButtons,
}) => {
  const isDesktop = useMediaQuery('(min-width:850px)');
  const { root, title, span, links, image } = useStyles({
    flexDirection: isDesktop ? (reverse ? 'row-reverse' : 'row') : 'column-reverse',
    imageHeight: isDesktop ? 706 : 395,
    imageWidth: isDesktop ? 540 : 300,
  });

  const eventTracker = (action: 'ios' | 'android', label = 'download-link') => {
    ReactGA.event({ category: 'LandingPage', action, label });
  };

  return (
    <div className={root}>
      <img src={imageURL} alt="App Demo" className={image} />
      <div>
        <div className={title}>
          <span className={span}>
            <Typography
              variant="h3"
              style={{ color: '#3A77D3', marginRight: 15, fontWeight: 'bold' }}
            >
              {titleText?.blueWord}
            </Typography>
            <Typography variant="h3" style={{ fontWeight: 'bold' }}>
              {titleText?.firstLine}
            </Typography>
          </span>
          <Typography variant="h3" style={{ fontWeight: 'bold' }}>
            {titleText?.secondLine}
          </Typography>
        </div>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          {subTitleText}
        </Typography>
        {storeButtons && (
          <>
            <div className={links}>
              <a
                href="https://apps.apple.com/us/app/yummy-swipe-with-friends/id1584855786"
                onClick={() => eventTracker('ios')}
              >
                <img src="/AppStore.png" alt="app store link" height={40} width={120} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.jamsquad.yummy"
                onClick={() => eventTracker('android')}
              >
                <img src="/GooglePlay.png" alt="play store link" height={40} width={120} />
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    display: 'flex',
    flexDirection: ({ flexDirection }) => flexDirection,
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '70%',
    paddingTop: 30,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
  span: {
    display: 'flex',
  },
  links: {
    display: 'flex',
    marginTop: 15,
    alignItems: 'left',
  },
  image: {
    height: ({ imageHeight }) => imageHeight,
    width: ({ imageWidth }) => imageWidth,
  },
}));

export default Content;
